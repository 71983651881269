// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-academic-online-courses-js": () => import("./../../../src/pages/academic-online-courses.js" /* webpackChunkName: "component---src-pages-academic-online-courses-js" */),
  "component---src-pages-academic-tutorials-js": () => import("./../../../src/pages/academic-tutorials.js" /* webpackChunkName: "component---src-pages-academic-tutorials-js" */),
  "component---src-pages-acoc-js": () => import("./../../../src/pages/acoc.js" /* webpackChunkName: "component---src-pages-acoc-js" */),
  "component---src-pages-admissions-test-preparation-js": () => import("./../../../src/pages/admissions-test-preparation.js" /* webpackChunkName: "component---src-pages-admissions-test-preparation-js" */),
  "component---src-pages-aoc-biotechnology-and-genetics-aoc-js": () => import("./../../../src/pages/aoc/biotechnology-and-genetics-aoc.js" /* webpackChunkName: "component---src-pages-aoc-biotechnology-and-genetics-aoc-js" */),
  "component---src-pages-aoc-business-and-entrepreneurship-aoc-js": () => import("./../../../src/pages/aoc/business-and-entrepreneurship-aoc.js" /* webpackChunkName: "component---src-pages-aoc-business-and-entrepreneurship-aoc-js" */),
  "component---src-pages-aoc-computer-science-aoc-js": () => import("./../../../src/pages/aoc/computer-science-aoc.js" /* webpackChunkName: "component---src-pages-aoc-computer-science-aoc-js" */),
  "component---src-pages-aoc-economics-aoc-js": () => import("./../../../src/pages/aoc/economics-aoc.js" /* webpackChunkName: "component---src-pages-aoc-economics-aoc-js" */),
  "component---src-pages-aoc-english-literature-and-creative-writing-aoc-js": () => import("./../../../src/pages/aoc/english-literature-and-creative-writing-aoc.js" /* webpackChunkName: "component---src-pages-aoc-english-literature-and-creative-writing-aoc-js" */),
  "component---src-pages-aoc-international-relations-and-politics-aoc-js": () => import("./../../../src/pages/aoc/international-relations-and-politics-aoc.js" /* webpackChunkName: "component---src-pages-aoc-international-relations-and-politics-aoc-js" */),
  "component---src-pages-aoc-js": () => import("./../../../src/pages/aoc.js" /* webpackChunkName: "component---src-pages-aoc-js" */),
  "component---src-pages-aoc-law-aoc-js": () => import("./../../../src/pages/aoc/law-aoc.js" /* webpackChunkName: "component---src-pages-aoc-law-aoc-js" */),
  "component---src-pages-aoc-mathematics-aoc-js": () => import("./../../../src/pages/aoc/mathematics-aoc.js" /* webpackChunkName: "component---src-pages-aoc-mathematics-aoc-js" */),
  "component---src-pages-aoc-medicine-aoc-js": () => import("./../../../src/pages/aoc/medicine-aoc.js" /* webpackChunkName: "component---src-pages-aoc-medicine-aoc-js" */),
  "component---src-pages-aoc-physics-aoc-js": () => import("./../../../src/pages/aoc/physics-aoc.js" /* webpackChunkName: "component---src-pages-aoc-physics-aoc-js" */),
  "component---src-pages-aoc-psychology-aoc-js": () => import("./../../../src/pages/aoc/psychology-aoc.js" /* webpackChunkName: "component---src-pages-aoc-psychology-aoc-js" */),
  "component---src-pages-bcourses-js": () => import("./../../../src/pages/bcourses.js" /* webpackChunkName: "component---src-pages-bcourses-js" */),
  "component---src-pages-blog-8-business-podcasts-to-listen-to-in-2022-js": () => import("./../../../src/pages/blog/8-business-podcasts-to-listen-to-in-2022.js" /* webpackChunkName: "component---src-pages-blog-8-business-podcasts-to-listen-to-in-2022-js" */),
  "component---src-pages-blog-a-beginners-guide-to-studying-economics-js": () => import("./../../../src/pages/blog/a-beginners-guide-to-studying-economics.js" /* webpackChunkName: "component---src-pages-blog-a-beginners-guide-to-studying-economics-js" */),
  "component---src-pages-blog-a-guide-to-choosing-the-right-university-abroad-js": () => import("./../../../src/pages/blog/a-guide-to-choosing-the-right-university-abroad.js" /* webpackChunkName: "component---src-pages-blog-a-guide-to-choosing-the-right-university-abroad-js" */),
  "component---src-pages-blog-a-guide-to-scholarships-for-international-students-js": () => import("./../../../src/pages/blog/a-guide-to-scholarships-for-international-students.js" /* webpackChunkName: "component---src-pages-blog-a-guide-to-scholarships-for-international-students-js" */),
  "component---src-pages-blog-active-study-techniques-that-actually-work-js": () => import("./../../../src/pages/blog/active-study-techniques-that-actually-work.js" /* webpackChunkName: "component---src-pages-blog-active-study-techniques-that-actually-work-js" */),
  "component---src-pages-blog-active-vs-passive-learning-js": () => import("./../../../src/pages/blog/active-vs-passive-learning.js" /* webpackChunkName: "component---src-pages-blog-active-vs-passive-learning-js" */),
  "component---src-pages-blog-advantages-of-online-international-tutoring-js": () => import("./../../../src/pages/blog/advantages-of-online-international-tutoring.js" /* webpackChunkName: "component---src-pages-blog-advantages-of-online-international-tutoring-js" */),
  "component---src-pages-blog-becoming-a-doctor-frequently-asked-questions-js": () => import("./../../../src/pages/blog/becoming-a-doctor-frequently-asked-questions.js" /* webpackChunkName: "component---src-pages-blog-becoming-a-doctor-frequently-asked-questions-js" */),
  "component---src-pages-blog-benefits-of-networking-for-students-js": () => import("./../../../src/pages/blog/benefits-of-networking-for-students.js" /* webpackChunkName: "component---src-pages-blog-benefits-of-networking-for-students-js" */),
  "component---src-pages-blog-benefits-online-learning-js": () => import("./../../../src/pages/blog/benefits-online-learning.js" /* webpackChunkName: "component---src-pages-blog-benefits-online-learning-js" */),
  "component---src-pages-blog-best-apps-for-students-in-2023-js": () => import("./../../../src/pages/blog/best-apps-for-students-in-2023.js" /* webpackChunkName: "component---src-pages-blog-best-apps-for-students-in-2023-js" */),
  "component---src-pages-blog-best-networking-tips-for-students-js": () => import("./../../../src/pages/blog/best-networking-tips-for-students.js" /* webpackChunkName: "component---src-pages-blog-best-networking-tips-for-students-js" */),
  "component---src-pages-blog-best-revision-techniques-for-a-levels-js": () => import("./../../../src/pages/blog/best-revision-techniques-for-a-levels.js" /* webpackChunkName: "component---src-pages-blog-best-revision-techniques-for-a-levels-js" */),
  "component---src-pages-blog-best-study-apps-2021-js": () => import("./../../../src/pages/blog/best-study-apps-2021.js" /* webpackChunkName: "component---src-pages-blog-best-study-apps-2021-js" */),
  "component---src-pages-blog-breaking-down-university-fees-js": () => import("./../../../src/pages/blog/breaking-down-university-fees.js" /* webpackChunkName: "component---src-pages-blog-breaking-down-university-fees-js" */),
  "component---src-pages-blog-business-studies-success-js": () => import("./../../../src/pages/blog/business-studies-success.js" /* webpackChunkName: "component---src-pages-blog-business-studies-success-js" */),
  "component---src-pages-blog-checklist-for-ucas-applications-js": () => import("./../../../src/pages/blog/checklist-for-ucas-applications.js" /* webpackChunkName: "component---src-pages-blog-checklist-for-ucas-applications-js" */),
  "component---src-pages-blog-choosing-a-uk-university-js": () => import("./../../../src/pages/blog/choosing-a-uk-university.js" /* webpackChunkName: "component---src-pages-blog-choosing-a-uk-university-js" */),
  "component---src-pages-blog-choosing-a-university-course-js": () => import("./../../../src/pages/blog/choosing-a-university-course.js" /* webpackChunkName: "component---src-pages-blog-choosing-a-university-course-js" */),
  "component---src-pages-blog-conquering-computer-science-js": () => import("./../../../src/pages/blog/conquering-computer-science.js" /* webpackChunkName: "component---src-pages-blog-conquering-computer-science-js" */),
  "component---src-pages-blog-entrepreneurship-books-to-motivate-you-js": () => import("./../../../src/pages/blog/entrepreneurship-books-to-motivate-you.js" /* webpackChunkName: "component---src-pages-blog-entrepreneurship-books-to-motivate-you-js" */),
  "component---src-pages-blog-excelling-in-english-literature-js": () => import("./../../../src/pages/blog/excelling-in-english-literature .js" /* webpackChunkName: "component---src-pages-blog-excelling-in-english-literature-js" */),
  "component---src-pages-blog-exploring-your-options-after-school-js": () => import("./../../../src/pages/blog/exploring-your-options-after-school.js" /* webpackChunkName: "component---src-pages-blog-exploring-your-options-after-school-js" */),
  "component---src-pages-blog-famous-figures-who-studied-at-uk-universities-js": () => import("./../../../src/pages/blog/famous-figures-who-studied-at-uk-universities .js" /* webpackChunkName: "component---src-pages-blog-famous-figures-who-studied-at-uk-universities-js" */),
  "component---src-pages-blog-famous-psychologists-and-their-theories-js": () => import("./../../../src/pages/blog/famous-psychologists-and-their-theories.js" /* webpackChunkName: "component---src-pages-blog-famous-psychologists-and-their-theories-js" */),
  "component---src-pages-blog-finding-the-right-course-for-you-js": () => import("./../../../src/pages/blog/finding-the-right-course-for-you.js" /* webpackChunkName: "component---src-pages-blog-finding-the-right-course-for-you-js" */),
  "component---src-pages-blog-five-tips-on-how-to-write-better-essays-js": () => import("./../../../src/pages/blog/five-tips-on-how-to-write-better-essays.js" /* webpackChunkName: "component---src-pages-blog-five-tips-on-how-to-write-better-essays-js" */),
  "component---src-pages-blog-future-trends-in-biotechnology-js": () => import("./../../../src/pages/blog/future-trends-in-biotechnology.js" /* webpackChunkName: "component---src-pages-blog-future-trends-in-biotechnology-js" */),
  "component---src-pages-blog-how-ai-is-relovationising-the-way-students-learn-js": () => import("./../../../src/pages/blog/how-ai-is-relovationising-the-way-students-learn.js" /* webpackChunkName: "component---src-pages-blog-how-ai-is-relovationising-the-way-students-learn-js" */),
  "component---src-pages-blog-how-many-a-levels-should-you-take-js": () => import("./../../../src/pages/blog/how-many-a-levels-should-you-take.js" /* webpackChunkName: "component---src-pages-blog-how-many-a-levels-should-you-take-js" */),
  "component---src-pages-blog-how-to-ace-your-upcoming-exams-js": () => import("./../../../src/pages/blog/how-to-ace-your-upcoming-exams.js" /* webpackChunkName: "component---src-pages-blog-how-to-ace-your-upcoming-exams-js" */),
  "component---src-pages-blog-how-to-answer-6-popular-university-questions-js": () => import("./../../../src/pages/blog/how-to-answer-6-popular-university-questions.js" /* webpackChunkName: "component---src-pages-blog-how-to-answer-6-popular-university-questions-js" */),
  "component---src-pages-blog-how-to-apply-to-top-uk-universities-js": () => import("./../../../src/pages/blog/how-to-apply-to-top-uk-universities.js" /* webpackChunkName: "component---src-pages-blog-how-to-apply-to-top-uk-universities-js" */),
  "component---src-pages-blog-how-to-beat-blue-monday-js": () => import("./../../../src/pages/blog/how-to-beat-blue-monday.js" /* webpackChunkName: "component---src-pages-blog-how-to-beat-blue-monday-js" */),
  "component---src-pages-blog-how-to-build-your-network-as-a-student-js": () => import("./../../../src/pages/blog/how-to-build-your-network-as-a-student.js" /* webpackChunkName: "component---src-pages-blog-how-to-build-your-network-as-a-student-js" */),
  "component---src-pages-blog-how-to-choose-gcse-subjects-js": () => import("./../../../src/pages/blog/how-to-choose-gcse-subjects.js" /* webpackChunkName: "component---src-pages-blog-how-to-choose-gcse-subjects-js" */),
  "component---src-pages-blog-how-to-choose-the-right-course-at-university-js": () => import("./../../../src/pages/blog/how-to-choose-the-right-course-at-university.js" /* webpackChunkName: "component---src-pages-blog-how-to-choose-the-right-course-at-university-js" */),
  "component---src-pages-blog-how-to-find-a-good-tutor-js": () => import("./../../../src/pages/blog/how-to-find-a-good-tutor.js" /* webpackChunkName: "component---src-pages-blog-how-to-find-a-good-tutor-js" */),
  "component---src-pages-blog-how-to-prepare-for-university-js": () => import("./../../../src/pages/blog/how-to-prepare-for-university.js" /* webpackChunkName: "component---src-pages-blog-how-to-prepare-for-university-js" */),
  "component---src-pages-blog-how-to-revise-gcse-mocks-js": () => import("./../../../src/pages/blog/how-to-revise-gcse-mocks.js" /* webpackChunkName: "component---src-pages-blog-how-to-revise-gcse-mocks-js" */),
  "component---src-pages-blog-how-to-select-best-online-courses-js": () => import("./../../../src/pages/blog/how-to-select-best-online-courses.js" /* webpackChunkName: "component---src-pages-blog-how-to-select-best-online-courses-js" */),
  "component---src-pages-blog-how-to-think-critically-js": () => import("./../../../src/pages/blog/how-to-think-critically.js" /* webpackChunkName: "component---src-pages-blog-how-to-think-critically-js" */),
  "component---src-pages-blog-how-to-write-a-personal-statement-for-sixth-form-js": () => import("./../../../src/pages/blog/how-to-write-a-personal-statement-for-sixth-form.js" /* webpackChunkName: "component---src-pages-blog-how-to-write-a-personal-statement-for-sixth-form-js" */),
  "component---src-pages-blog-how-to-write-your-personal-statement-js": () => import("./../../../src/pages/blog/how-to-write-your-personal-statement.js" /* webpackChunkName: "component---src-pages-blog-how-to-write-your-personal-statement-js" */),
  "component---src-pages-blog-interesting-careers-for-psychology-graduates-js": () => import("./../../../src/pages/blog/interesting-careers-for-psychology-graduates.js" /* webpackChunkName: "component---src-pages-blog-interesting-careers-for-psychology-graduates-js" */),
  "component---src-pages-blog-international-students-benefits-of-studying-in-the-uk-js": () => import("./../../../src/pages/blog/international-students-benefits-of-studying-in-the-uk.js" /* webpackChunkName: "component---src-pages-blog-international-students-benefits-of-studying-in-the-uk-js" */),
  "component---src-pages-blog-introduction-to-arts-and-humanities-js": () => import("./../../../src/pages/blog/introduction-to-arts-and-humanities.js" /* webpackChunkName: "component---src-pages-blog-introduction-to-arts-and-humanities-js" */),
  "component---src-pages-blog-introduction-to-creative-writing-js": () => import("./../../../src/pages/blog/introduction-to-creative-writing.js" /* webpackChunkName: "component---src-pages-blog-introduction-to-creative-writing-js" */),
  "component---src-pages-blog-introduction-to-economics-js": () => import("./../../../src/pages/blog/introduction-to-economics.js" /* webpackChunkName: "component---src-pages-blog-introduction-to-economics-js" */),
  "component---src-pages-blog-introduction-to-gcses-js": () => import("./../../../src/pages/blog/introduction-to-gcses.js" /* webpackChunkName: "component---src-pages-blog-introduction-to-gcses-js" */),
  "component---src-pages-blog-introduction-to-international-relations-js": () => import("./../../../src/pages/blog/introduction-to-international-relations.js" /* webpackChunkName: "component---src-pages-blog-introduction-to-international-relations-js" */),
  "component---src-pages-blog-jobs-you-can-do-with-a-maths-degree-js": () => import("./../../../src/pages/blog/jobs-you-can-do-with-a-maths-degree.js" /* webpackChunkName: "component---src-pages-blog-jobs-you-can-do-with-a-maths-degree-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-law-terms-every-student-should-know-js": () => import("./../../../src/pages/blog/law-terms-every-student-should-know.js" /* webpackChunkName: "component---src-pages-blog-law-terms-every-student-should-know-js" */),
  "component---src-pages-blog-mastering-mathematics-js": () => import("./../../../src/pages/blog/mastering-mathematics.js" /* webpackChunkName: "component---src-pages-blog-mastering-mathematics-js" */),
  "component---src-pages-blog-mastering-your-ucas-application-js": () => import("./../../../src/pages/blog/mastering-your-ucas-application.js" /* webpackChunkName: "component---src-pages-blog-mastering-your-ucas-application-js" */),
  "component---src-pages-blog-medicine-interview-questions-js": () => import("./../../../src/pages/blog/medicine-interview-questions.js" /* webpackChunkName: "component---src-pages-blog-medicine-interview-questions-js" */),
  "component---src-pages-blog-melio-education-js": () => import("./../../../src/pages/blog/melio-education.js" /* webpackChunkName: "component---src-pages-blog-melio-education-js" */),
  "component---src-pages-blog-melio-education-university-fair-recap-js": () => import("./../../../src/pages/blog/Melio-education-university-fair-recap.js" /* webpackChunkName: "component---src-pages-blog-melio-education-university-fair-recap-js" */),
  "component---src-pages-blog-most-famous-physicists-of-all-time-js": () => import("./../../../src/pages/blog/most-famous-physicists-of-all-time.js" /* webpackChunkName: "component---src-pages-blog-most-famous-physicists-of-all-time-js" */),
  "component---src-pages-blog-must-read-books-for-computer-science-students-js": () => import("./../../../src/pages/blog/must-read-books-for-computer-science-students.js" /* webpackChunkName: "component---src-pages-blog-must-read-books-for-computer-science-students-js" */),
  "component---src-pages-blog-must-read-law-books-for-students-js": () => import("./../../../src/pages/blog/must-read-law-books-for-students.js" /* webpackChunkName: "component---src-pages-blog-must-read-law-books-for-students-js" */),
  "component---src-pages-blog-my-experience-studying-computer-science-js": () => import("./../../../src/pages/blog/my-experience-studying-computer-science.js" /* webpackChunkName: "component---src-pages-blog-my-experience-studying-computer-science-js" */),
  "component---src-pages-blog-navigating-cultural-differences-at-university-js": () => import("./../../../src/pages/blog/navigating-cultural-differences-at-university .js" /* webpackChunkName: "component---src-pages-blog-navigating-cultural-differences-at-university-js" */),
  "component---src-pages-blog-note-taking-strategies-for-university-students-js": () => import("./../../../src/pages/blog/note-taking-strategies-for-university-students.js" /* webpackChunkName: "component---src-pages-blog-note-taking-strategies-for-university-students-js" */),
  "component---src-pages-blog-online-learning-vs-classroom-learning-js": () => import("./../../../src/pages/blog/online-learning-vs-classroom-learning.js" /* webpackChunkName: "component---src-pages-blog-online-learning-vs-classroom-learning-js" */),
  "component---src-pages-blog-parent-guide-to-online-learning-js": () => import("./../../../src/pages/blog/parent-guide-to-online-learning.js" /* webpackChunkName: "component---src-pages-blog-parent-guide-to-online-learning-js" */),
  "component---src-pages-blog-preparing-to-study-in-the-uk-js": () => import("./../../../src/pages/blog/preparing-to-study-in-the-uk.js" /* webpackChunkName: "component---src-pages-blog-preparing-to-study-in-the-uk-js" */),
  "component---src-pages-blog-psychology-introduction-js": () => import("./../../../src/pages/blog/psychology-introduction.js" /* webpackChunkName: "component---src-pages-blog-psychology-introduction-js" */),
  "component---src-pages-blog-reasons-to-study-english-at-university-js": () => import("./../../../src/pages/blog/reasons-to-study-english-at-university.js" /* webpackChunkName: "component---src-pages-blog-reasons-to-study-english-at-university-js" */),
  "component---src-pages-blog-revision-techniques-without-ruining-christmas-js": () => import("./../../../src/pages/blog/revision-techniques-without-ruining-christmas.js" /* webpackChunkName: "component---src-pages-blog-revision-techniques-without-ruining-christmas-js" */),
  "component---src-pages-blog-seven-tips-to-prepare-for-university-js": () => import("./../../../src/pages/blog/seven-tips-to-prepare-for-university.js" /* webpackChunkName: "component---src-pages-blog-seven-tips-to-prepare-for-university-js" */),
  "component---src-pages-blog-skills-to-include-on-cv-js": () => import("./../../../src/pages/blog/skills-to-include-on-cv.js" /* webpackChunkName: "component---src-pages-blog-skills-to-include-on-cv-js" */),
  "component---src-pages-blog-study-data-science-in-the-uk-js": () => import("./../../../src/pages/blog/study-data-science-in-the-uk.js" /* webpackChunkName: "component---src-pages-blog-study-data-science-in-the-uk-js" */),
  "component---src-pages-blog-study-motivation-tips-for-home-learning-js": () => import("./../../../src/pages/blog/study-motivation-tips-for-home-learning.js" /* webpackChunkName: "component---src-pages-blog-study-motivation-tips-for-home-learning-js" */),
  "component---src-pages-blog-the-best-8-universities-in-the-uk-for-international-students-js": () => import("./../../../src/pages/blog/the-best-8-universities-in-the-UK-for-international-students.js" /* webpackChunkName: "component---src-pages-blog-the-best-8-universities-in-the-uk-for-international-students-js" */),
  "component---src-pages-blog-the-ultimate-business-school-guide-js": () => import("./../../../src/pages/blog/the-ultimate-business-school-guide.js" /* webpackChunkName: "component---src-pages-blog-the-ultimate-business-school-guide-js" */),
  "component---src-pages-blog-tips-for-working-from-home-js": () => import("./../../../src/pages/blog/tips-for-working-from-home.js" /* webpackChunkName: "component---src-pages-blog-tips-for-working-from-home-js" */),
  "component---src-pages-blog-tips-to-overcoming-fears-about-university-js": () => import("./../../../src/pages/blog/tips-to-overcoming-fears-about-university.js" /* webpackChunkName: "component---src-pages-blog-tips-to-overcoming-fears-about-university-js" */),
  "component---src-pages-blog-top-scholarships-for-undergraduate-students-js": () => import("./../../../src/pages/blog/top-scholarships-for-undergraduate-students.js" /* webpackChunkName: "component---src-pages-blog-top-scholarships-for-undergraduate-students-js" */),
  "component---src-pages-blog-top-ten-famous-mathematicians-js": () => import("./../../../src/pages/blog/top-ten-famous-mathematicians.js" /* webpackChunkName: "component---src-pages-blog-top-ten-famous-mathematicians-js" */),
  "component---src-pages-blog-ucas-guide-for-international-students-js": () => import("./../../../src/pages/blog/ucas-guide-for-international-students.js" /* webpackChunkName: "component---src-pages-blog-ucas-guide-for-international-students-js" */),
  "component---src-pages-blog-ucas-personal-statement-guidance-js": () => import("./../../../src/pages/blog/UCAS-personal-statement-guidance.js" /* webpackChunkName: "component---src-pages-blog-ucas-personal-statement-guidance-js" */),
  "component---src-pages-blog-ucas-removes-personal-statement-for-2024-js": () => import("./../../../src/pages/blog/ucas-removes-personal-statement-for-2024.js" /* webpackChunkName: "component---src-pages-blog-ucas-removes-personal-statement-for-2024-js" */),
  "component---src-pages-blog-ultimate-university-guide-for-uk-universities-js": () => import("./../../../src/pages/blog/ultimate-university-guide-for-uk-universities .js" /* webpackChunkName: "component---src-pages-blog-ultimate-university-guide-for-uk-universities-js" */),
  "component---src-pages-blog-understanding-different-visas-for-international-students-js": () => import("./../../../src/pages/blog/understanding-different-visas-for-international-students.js" /* webpackChunkName: "component---src-pages-blog-understanding-different-visas-for-international-students-js" */),
  "component---src-pages-blog-understanding-the-vark-theory-js": () => import("./../../../src/pages/blog/understanding-the-VARK-theory.js" /* webpackChunkName: "component---src-pages-blog-understanding-the-vark-theory-js" */),
  "component---src-pages-blog-university-open-day-checklist-js": () => import("./../../../src/pages/blog/university-open-day-checklist.js" /* webpackChunkName: "component---src-pages-blog-university-open-day-checklist-js" */),
  "component---src-pages-blog-university-open-day-guide-js": () => import("./../../../src/pages/blog/university-open-day-guide.js" /* webpackChunkName: "component---src-pages-blog-university-open-day-guide-js" */),
  "component---src-pages-blog-vark-different-learning-styles-js": () => import("./../../../src/pages/blog/vark-different-learning-styles.js" /* webpackChunkName: "component---src-pages-blog-vark-different-learning-styles-js" */),
  "component---src-pages-blog-what-are-ucas-points-js": () => import("./../../../src/pages/blog/what-are-ucas-points.js" /* webpackChunkName: "component---src-pages-blog-what-are-ucas-points-js" */),
  "component---src-pages-blog-what-is-a-placement-year-js": () => import("./../../../src/pages/blog/what-is-a-placement-year.js" /* webpackChunkName: "component---src-pages-blog-what-is-a-placement-year-js" */),
  "component---src-pages-blog-what-is-adaptive-learning-js": () => import("./../../../src/pages/blog/what-is-adaptive-learning.js" /* webpackChunkName: "component---src-pages-blog-what-is-adaptive-learning-js" */),
  "component---src-pages-blog-what-is-blended-learning-js": () => import("./../../../src/pages/blog/what-is-blended-learning.js" /* webpackChunkName: "component---src-pages-blog-what-is-blended-learning-js" */),
  "component---src-pages-blog-what-is-digital-wellbeing-js": () => import("./../../../src/pages/blog/what-is-digital-wellbeing.js" /* webpackChunkName: "component---src-pages-blog-what-is-digital-wellbeing-js" */),
  "component---src-pages-blog-what-is-distance-learning-js": () => import("./../../../src/pages/blog/what-is-distance-learning.js" /* webpackChunkName: "component---src-pages-blog-what-is-distance-learning-js" */),
  "component---src-pages-blog-what-is-robotics-js": () => import("./../../../src/pages/blog/what-is-robotics.js" /* webpackChunkName: "component---src-pages-blog-what-is-robotics-js" */),
  "component---src-pages-blog-what-to-do-after-school-js": () => import("./../../../src/pages/blog/what-to-do-after-school.js" /* webpackChunkName: "component---src-pages-blog-what-to-do-after-school-js" */),
  "component---src-pages-blog-why-a-uk-medical-degree-is-a-smart-choice-js": () => import("./../../../src/pages/blog/why-a-uk-medical-degree-is-a-smart-choice.js" /* webpackChunkName: "component---src-pages-blog-why-a-uk-medical-degree-is-a-smart-choice-js" */),
  "component---src-pages-blog-why-online-learning-is-effective-js": () => import("./../../../src/pages/blog/why-online-learning-is-effective.js" /* webpackChunkName: "component---src-pages-blog-why-online-learning-is-effective-js" */),
  "component---src-pages-blog-why-studying-law-at-university-is-a-smart-choice-js": () => import("./../../../src/pages/blog/why-studying-law-at-university-is-a-smart-choice.js" /* webpackChunkName: "component---src-pages-blog-why-studying-law-at-university-is-a-smart-choice-js" */),
  "component---src-pages-career-guidance-js": () => import("./../../../src/pages/career-guidance.js" /* webpackChunkName: "component---src-pages-career-guidance-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-carousel-js": () => import("./../../../src/pages/carousel.js" /* webpackChunkName: "component---src-pages-carousel-js" */),
  "component---src-pages-contact-contact-us-js": () => import("./../../../src/pages/contact/contact-us.js" /* webpackChunkName: "component---src-pages-contact-contact-us-js" */),
  "component---src-pages-courses-js": () => import("./../../../src/pages/courses.js" /* webpackChunkName: "component---src-pages-courses-js" */),
  "component---src-pages-courses-university-preparation-program-js": () => import("./../../../src/pages/courses/university-preparation-program.js" /* webpackChunkName: "component---src-pages-courses-university-preparation-program-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-form-js": () => import("./../../../src/pages/form.js" /* webpackChunkName: "component---src-pages-form-js" */),
  "component---src-pages-forms-sf-student-apply-js": () => import("./../../../src/pages/forms/sf/student/apply.js" /* webpackChunkName: "component---src-pages-forms-sf-student-apply-js" */),
  "component---src-pages-forms-sf-tutor-apply-js": () => import("./../../../src/pages/forms/sf/tutor/apply.js" /* webpackChunkName: "component---src-pages-forms-sf-tutor-apply-js" */),
  "component---src-pages-help-js": () => import("./../../../src/pages/help.js" /* webpackChunkName: "component---src-pages-help-js" */),
  "component---src-pages-how-it-works-js": () => import("./../../../src/pages/how-it-works.js" /* webpackChunkName: "component---src-pages-how-it-works-js" */),
  "component---src-pages-how-melio-works-js": () => import("./../../../src/pages/how-melio-works.js" /* webpackChunkName: "component---src-pages-how-melio-works-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-interview-preparation-js": () => import("./../../../src/pages/interview-preparation.js" /* webpackChunkName: "component---src-pages-interview-preparation-js" */),
  "component---src-pages-new-features-js": () => import("./../../../src/pages/new-features.js" /* webpackChunkName: "component---src-pages-new-features-js" */),
  "component---src-pages-oxbridge-webinar-recording-js": () => import("./../../../src/pages/oxbridge-webinar-recording.js" /* webpackChunkName: "component---src-pages-oxbridge-webinar-recording-js" */),
  "component---src-pages-personal-statement-support-js": () => import("./../../../src/pages/personal-statement-support.js" /* webpackChunkName: "component---src-pages-personal-statement-support-js" */),
  "component---src-pages-premium-university-guidance-js": () => import("./../../../src/pages/premium-university-guidance.js" /* webpackChunkName: "component---src-pages-premium-university-guidance-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-programs-and-packages-js": () => import("./../../../src/pages/programs-and-packages.js" /* webpackChunkName: "component---src-pages-programs-and-packages-js" */),
  "component---src-pages-quiz-js": () => import("./../../../src/pages/quiz.js" /* webpackChunkName: "component---src-pages-quiz-js" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-schools-js": () => import("./../../../src/pages/schools.js" /* webpackChunkName: "component---src-pages-schools-js" */),
  "component---src-pages-study-in-the-uk-js": () => import("./../../../src/pages/study-in-the-uk.js" /* webpackChunkName: "component---src-pages-study-in-the-uk-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-pages-tutors-js": () => import("./../../../src/pages/tutors.js" /* webpackChunkName: "component---src-pages-tutors-js" */),
  "component---src-pages-undecided-js": () => import("./../../../src/pages/undecided.js" /* webpackChunkName: "component---src-pages-undecided-js" */),
  "component---src-pages-uniguidance-js": () => import("./../../../src/pages/uniguidance.js" /* webpackChunkName: "component---src-pages-uniguidance-js" */),
  "component---src-pages-university-guidance-for-schools-pdf-js": () => import("./../../../src/pages/university-guidance-for-schools-pdf.js" /* webpackChunkName: "component---src-pages-university-guidance-for-schools-pdf-js" */),
  "component---src-pages-university-guidance-js": () => import("./../../../src/pages/university-guidance.js" /* webpackChunkName: "component---src-pages-university-guidance-js" */),
  "component---src-pages-university-guidance-pdf-js": () => import("./../../../src/pages/university-guidance-pdf.js" /* webpackChunkName: "component---src-pages-university-guidance-pdf-js" */),
  "component---src-pages-university-preparation-js": () => import("./../../../src/pages/university-preparation.js" /* webpackChunkName: "component---src-pages-university-preparation-js" */),
  "component---src-pages-university-study-skills-js": () => import("./../../../src/pages/university-study-skills.js" /* webpackChunkName: "component---src-pages-university-study-skills-js" */),
  "component---src-pages-university-webinars-js": () => import("./../../../src/pages/university-webinars.js" /* webpackChunkName: "component---src-pages-university-webinars-js" */),
  "component---src-pages-universityguidance-apply-js": () => import("./../../../src/pages/universityguidance-apply.js" /* webpackChunkName: "component---src-pages-universityguidance-apply-js" */),
  "component---src-pages-upcoming-course-dates-js": () => import("./../../../src/pages/upcoming-course-dates.js" /* webpackChunkName: "component---src-pages-upcoming-course-dates-js" */),
  "component---src-pages-webinar-signup-js": () => import("./../../../src/pages/webinar-signup.js" /* webpackChunkName: "component---src-pages-webinar-signup-js" */),
  "component---src-pages-where-to-study-js": () => import("./../../../src/pages/where-to-study.js" /* webpackChunkName: "component---src-pages-where-to-study-js" */)
}


import { red } from '@material-ui/core/colors'
import { createTheme } from '@material-ui/core/styles'

// A custom theme for this app
const theme = createTheme({
    typography: {
        fontFamily: ['IBM Plex Sans'].join(','),
        h1: {
            fontSize: '3.375rem', //54px
        },
        h2: {
            fontSize: '2.25rem', //36px
        },
        h3: {
            fontSize: '1.75rem', //28px
        },
        h4: {
            fontSize: '1.5rem', //24px
        },
        h5: {
            fontSize: '1.25rem', //20px
        },
        h6: {
            fontSize: '1.125rem', //18px
        },
        body1: {
            fontSize: '1.5rem', //24px
        },
        body2: {
            fontSize: '0.875rem', //14px
        },
        button: {
            fontSize: '1.25rem', //20px
            fontWeight: 'normal',
            textTransform: 'none',
        },
    },
    overrides: {
        MuiPaper: {
            root: {
                boxShadow:
                    '0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)',
            },
        },
        MuiTab: {
            root: {
                minWidth: '300px',
                maxWidth: '300px',
            },
        },
    },
    palette: {
        primary: {
            main: '#FFFFFF',
            light: '#CBE4DE',
            dark: '#001756',
            gray: '#D2D3D5',
            black: '#130D1F',
            contrastText: '#130D1F',
        },
        secondary: {
            main: '#F45F00',
            light: '#FFF',
            black: '#130D1F',
            dark: '#4B4F56',
            gray: '#eee',
            darkMain: '#c56212',
        },
        error: {
            main: red.A400,
            light: red.A100,
        },
        background: {
            default: '#FFFFFF',
            green: '#E3F5F2',
            creme: 'rgba(238, 233, 229, 0.5)',
            black: '#0F0518',
        },
        text: {
            green: '#75CCBD',
            black: '#0F0518',
            gray: 'rgba(15, 5, 24, 0.6)',
            white: '#FFFFFF',
            orange: '#F1571E',
        },
    },
})

export default theme

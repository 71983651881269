import './src/style/global.css'

import React, { useEffect, useState } from 'react'
import * as FlexWebChat from '@twilio/flex-webchat-ui'

const useWebchat = () => {
    const [webchat, setWebchat] = useState(null)
    const [webchatError, setWebchatError] = useState(null)
    useEffect(() => {
        const configuration = window.appConfig
        if (!webchat) {
            FlexWebChat.Manager.create(configuration)
                // .then(manager => {
                //     manager.strings.PredefinedChatMessageAuthorName = "Agent"
                //     console.log('manager:', manager)
                // })
                // .catch((error) => console.log('error1', error))
                .then((webchat) => setWebchat(webchat))
                .catch((error) => {
                    console.log('error2', error)
                    setWebchatError({ error })
                })
        }
    }, [webchat])
    return webchat
}

const ChatProvider = () => {
    const webchat = useWebchat()
    if (!webchat) return null
    return (
        <FlexWebChat.ContextProvider manager={webchat}>
            <FlexWebChat.RootContainer />
        </FlexWebChat.ContextProvider>
    )
}

export const wrapRootElement = ({ element }) => {
    return (
        <>
            {/*<ChatProvider />*/}
            {element}
        </>
    )
}
